.App {
  background: url('/public/img/mainBg.png') no-repeat center center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 30px 100px 0 100px; 
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 1300px) {
    padding: 20px 24px 0 24px;
  }

  @media (max-width: 600px) {
    padding: 10px;
  }
}

.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000026;
  z-index: 0; 
}

.App-logo {
  height: 69px;
  width: 177px;
  pointer-events: none;
  z-index: 2;
  margin-left: 340px;


  @media(max-width: 1700px) {
    margin-left: 0;
  }

  @media (max-width: 600px) {
    height: 30px !important;
    width: 100px !important;
  }
}

.limitation {
  width: 18px;
  height: 18px;
}








